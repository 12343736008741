.valentines-two-page {
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
  position: relative;

  @font-face {
    font-family: "JetBrainsBold";
    src: url(../fonts/JetBrainsMono-Bold.ttf);
    font-weight: bold;
  }

  @font-face {
    font-family: "JetBrainsBolder";
    src: url(../fonts/JetBrainsMono-ExtraBold.ttf);
    font-weight: bolder;
  }

  @font-face {
    font-family: "Sunflower";
    src: url(../fonts/sunflower.otf);
    font-weight: regular;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 2em;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  nav p {
    text-transform: uppercase;
    font-family: "Sunflower", JetBrainsBold, monospace;
    font-size: 12px;
    font-weight: 600;
    padding: 4px;
    background-color: #d4f70c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .header {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    transform-style: preserve-3d;
    perspective: 1000px;
    will-change: transform;
    z-index: 2;
  }
  
  .header h1 {
    text-transform: uppercase;
    font-family: "Sunflower", JetBrainsBolder, monospace;
    font-size: 7.5vw;
    letter-spacing: -0.025em;
    line-height: 1;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    user-select: none;
  }
  
  .yes-button {
    text-transform: uppercase;
    font-family: "Sunflower", JetBrainsBolder, monospace;  
    font-size: 12px;
    font-weight: 600;
    margin: 8px;
    padding: 8px;
    background-color: #d4f70c;
  }

  .no-button {
    text-transform: uppercase;
    font-family: "Sunflower", JetBrainsBolder, monospace;  
    font-size: 12px;
    font-weight: 600;
    margin: 8px;
    padding: 8px;
    background-color: #FF2400;
  }
}
